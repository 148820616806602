import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import Export from './Export';
import usePostApi from './services/usePostApi';
import Count from "./Count";
import { useOktaAuth } from '@okta/okta-react';
import {  AutocompleteSelect, Button, MessageDialog, UserButton  } from "tgsccl-core-mui";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataCount,CountyOption, StateOptionsData, UserInfoData } from "./IselectInput-interface";
import { AutoSelectOption } from 'tgsccl-core-mui/components/auto-complete-select/auto-complete-select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { AutocompleteChangeDetails, AutocompleteChangeReason, styled } from '@mui/material';
import {ui} from 'tgsccl-core';
import { format, getBodyWithCounty, getBodyWithStateAndCounty, getWellCount, onLoadCountyInputBody, onLoadStateCount, onLoadStateInputBody, selectedStateInputBody, specialChar } from './utils/search-input-util';
const { Header } = ui;

function Home() {
    const postAPI = usePostApi();
    const [countWell, setCountWell] = useState(0);
    const { oktaAuth,authState } = useOktaAuth();
    const [showLogin, setShowLogin] = useState(false);
    const [isDis, setIsDis] = useState(false);
    const isLength = useRef(true);
    const [loaded, setLoaded] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const logout = async () => { await oktaAuth.signOut(); }
    const [userInfo, setUserInfo] = useState<UserInfoData | null>(null);
    const [state, setValue] = useState({
        value: 0,
        statesToQuery:[''],
        statesFromSelectedCounties:[''],
        countiesToQUery:[''],
        conutyValue: 0,
        totalCountOfState: 0,
        totalCountOfCounty: 0,
        isCountylist: [],
        removeDup: [''],
        getsameCounty: []
    });
   
    const [count, setcount] = useState<DataCount>({
        wellcount: 0,
        getWellCount: 0,
        stateWellCount: 0,
        countyWellCount: 0,

        wellProductionCount: 0,
        stateProductionCount: 0,
        countyProductionCount: 0,
        getProductionCount: 0,

        wellActiveProducingCount: 0,
        statewellActiveProducingCount: 0,
        countywellActiveProducingCount: 0,
        getwellActiveProducingCount: 0,

        slantSideTrackCount: 0,
        stateslantSideTrackCount: 0,
        countyslantSideTrackCount: 0,
        getslantSideTrackCount: 0,

        slantDirectionalCount: 0,
        stateslantDirectionalCount: 0,
        countyslantDirectionalCount: 0,
        getslantDirectionalCount: 0,

        slantVerticalCount: 0,
        stateslantVerticalCount: 0,
        countyslantVerticalCount: 0,
        getslantVerticalCount: 0,

        slantHorizontalCount: 0,
        stateslantHorizontalCount: 0,
        countyslantHorizontalCount: 0,
        getslantHorizontalCount: 0,

        unkowncount: 0,
        stateUnknownCount: 0,
        countyUnknownCount: 0,
        getUnknownCount: 0,

        isselectedStaCountCount: 0,
        isSelectedCountyTotal: 0,
        isProductionCount: 0,
        selectedStateCount: 0,
        countget: 0
    });

    const [isFileData, setIsFileData] = React.useState("");

    const prevCountRef = useRef(count.countyWellCount);
    const prevCountProd = useRef(count.countyProductionCount);
    const prevCountActiveProd = useRef(count.countywellActiveProducingCount);
    const prevCountSlantside = useRef(count.countyslantSideTrackCount);
    const prevCountSlantDirect = useRef(count.countyslantDirectionalCount);
    const prevCountSlantVertical = useRef(count.countyslantVerticalCount);
    const prevCountSlantHorizontal = useRef(count.countyslantHorizontalCount);
    const [selectedStateOption, setSelectedStateOption] = useState<AutoSelectOption[]>([]);
    const [selectedCountyOption, setSelectedCountyOption] = useState<CountyOption[]>([]);
    const [allCountyOptions, setAllCountyOptions] = useState<CountyOption[]>([]);
    const [stateOptions, setStateOptions] = useState<StateOptionsData[]>([]);
    const [countyOptions, setCountyOptions] = useState<CountyOption[]>([]);

    const sortIt = (sortBy: string) => (a:{[key:string]:number}, b:{[key:string]:number}) => {
        if (a[sortBy] > b[sortBy]) {
            return 1;
        } else if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        return 0;
    }

    async function isListofStatesAndCounty() {
        setLoaded(true);
        let statebody = onLoadStateInputBody();
        let countyBody = onLoadCountyInputBody();
        const getDataForState = await postAPI(statebody);
        const isListOfStatesresponse = getDataForState?.data.Aggregations;
        setLoaded(false);
        const totalCountOfState = isListOfStatesresponse.length;
        const sortedByState = isListOfStatesresponse.sort(sortIt('Key'));
        setStateOptions(sortedByState);
        let stateValue = [];
        for (let stateList of isListOfStatesresponse) {
            stateValue.push(stateList.Key);
        }
        setLoaded(true);
        const getDateForCounty = await postAPI(countyBody);
        setLoaded(false);
        const isListOfCountyresponse = getDateForCounty?.data.Documents;
        let tempAllCountyOption:CountyOption[] =[];
        isListOfCountyresponse.forEach((countyRep:any) => {
            if(countyRep.StateCounty){
                tempAllCountyOption.push({ label: countyRep.StateCounty, key: countyRep.StateCounty, State: countyRep.State, Id: countyRep.Id, Country: countyRep.Country, StateCode : countyRep.StateCode });
            }
          });

        const totalCountOfCounty = tempAllCountyOption.length;
        tempAllCountyOption = tempAllCountyOption.sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()));
        setCountyOptions(tempAllCountyOption);
        setAllCountyOptions(tempAllCountyOption);
        isOnLoadStateCount(stateValue);
        setValue({ ...state, totalCountOfCounty, totalCountOfState });
    }
    const onSelectCounty = (e: React.SyntheticEvent<Element, Event>, value: unknown) => {
         const valueSelect = value as CountyOption[]
         setSelectedCountyOption(valueSelect);
        if (selectedStateOption.length === 0 && valueSelect.length === 0) {
            isListofStatesAndCounty();
        }
        if (valueSelect.length > selectedCountyOption.length) {
            if (valueSelect.length === 0 && selectedStateOption.length > 0) {
                isStateSelected(selectedStateOption, valueSelect);
            } /*else if (valueSelect.length > 0 && selectedStateOption.length == 0) {
                isCountySelected(valueSelect, selectedStateOption);
            } else if (valueSelect.length > 0 && selectedStateOption.length > 0) {
                isCountySelected(valueSelect, selectedStateOption);
            }*/
            else {
                isCountySelected(valueSelect, selectedStateOption);
            }
        } else {
            isCountyClear(valueSelect)
        }
    }
    const isSelectedStateClear = (valueSelect: AutoSelectOption[]) => {
        const clearedCounties = selectedCountyOption.filter((item) => !valueSelect.some((re:AutoSelectOption) => item.State === re.label));
        setSelectedCountyOption(clearedCounties);
        if (clearedCounties.length > 0) {
            isCountySelected(clearedCounties, valueSelect);
        } else {
            isListofStatesAndCounty();
        }
    }
    const getStateAndCountyData = (valueSelect: AutoSelectOption[]) => {
        const filteredCountiies = allCountyOptions.filter((item:CountyOption) => valueSelect.some((re:AutoSelectOption) => item.State === re.label));
        setCountyOptions(filteredCountiies);
        if (valueSelect.length > 0 && selectedCountyOption.length > 0) {
            isCountySelected(selectedCountyOption, valueSelect);
        }
        if (valueSelect.length > 0 && selectedCountyOption.length === 0) {
            isStateSelected(valueSelect, selectedCountyOption);
        }
    }
    const onSelectState = (e: React.SyntheticEvent<Element, Event>, value: unknown,  reason?: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        const valueSelect = value as AutoSelectOption[]
       setSelectedStateOption(valueSelect);
        if (reason === 'clear') {
            isSelectedStateClear(valueSelect);
        } else {
            if (valueSelect.length === 0 && selectedCountyOption.length === 0) {
                isListofStatesAndCounty();
            }
            if (valueSelect.length > selectedStateOption.length) {
                getStateAndCountyData(valueSelect);
            } else {
                const filteredSelCountiies11 = allCountyOptions.filter((item:CountyOption) => valueSelect.some((re:AutoSelectOption) => item.State === re.label));
                setCountyOptions(filteredSelCountiies11);
                isStateClear(valueSelect);
            }
        }
    }
    async function isOnLoadStateCount(states:string[]) {
        setLoaded(true);
        let ipBody = onLoadStateCount(states);
        const isDataStateCount = await postAPI(ipBody);
        setLoaded(false);
        const isDataResponse = isDataStateCount?.data.Documents;
        let wellcount = 0;
        let wellProductionCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let unkowncount = 0;
        for (let resStateData of isDataResponse) {
            wellcount += resStateData.WellCount;
            wellProductionCount += resStateData.WellProductionCount;
            wellActiveProducingCount += resStateData.WellActiveProducingCount;
            slantSideTrackCount += resStateData.SlantSideTrackCount;
            slantDirectionalCount += resStateData.SlantDirectionalCount;
            slantVerticalCount += resStateData.SlantVerticalCount;
            slantHorizontalCount += resStateData.SlantHorizontalCount;
        }
        unkowncount = wellcount - slantVerticalCount - slantSideTrackCount - slantDirectionalCount - slantHorizontalCount;
        setcount({ ...count, wellcount, wellProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, unkowncount })
    }

    function sessionKill() {
        setIsDialogOpen(true);
    }

    useEffect(() => {
        oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
            console.log('Token with key', key, ' has expired:');
            if (key === 'accessToken') {
                sessionKill();
            }
        });
        //assign the ref's current value to the count Hook
        prevCountRef.current = count.countyWellCount;
        prevCountProd.current = count.countyProductionCount;
        prevCountActiveProd.current = count.countywellActiveProducingCount;
        prevCountSlantside.current = count.countyslantSideTrackCount;
        prevCountSlantDirect.current = count.countyslantDirectionalCount;
        prevCountSlantHorizontal.current = count.countyslantHorizontalCount;
        prevCountSlantVertical.current = count.countyslantVerticalCount;
    }, [count.countyWellCount, count.countyProductionCount, count.countywellActiveProducingCount, count.countyslantSideTrackCount,
    count.countyslantDirectionalCount, count.countyslantHorizontalCount, count.countyslantVerticalCount]);
    
    async function isStateSelected(paramstate:AutoSelectOption[], paramCounty:CountyOption[]) {
        let statesToQuery:string[] = [];
        let statesFromSelectedCounties:string[]=[];
        let countiesToQUery:string[] = [];
        for (let county of paramCounty) {
            statesFromSelectedCounties.push(county.State);
            countiesToQUery.push(county.label.substring(5));
        }
        statesFromSelectedCounties = [...new Set(statesFromSelectedCounties)];
        for (let stateParam of paramstate) {
            statesToQuery.push(stateParam.label);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let ipBody = selectedStateInputBody(statesToQuery);
        const responseData = await postAPI(ipBody);
        setLoaded(false);
        const isSelectedState = responseData?.data.Documents;
        const isSelectedCountyCount = responseData?.data.Aggregations;
        let isselectedStaCountCount = 0;
        let isSelectedCountyTotal = 0;
        let selectedStateCount = 0;
        let stateWellCount = 0;
        let stateProductionCount = 0;
        let statewellActiveProducingCount = 0;
        let stateslantSideTrackCount = 0;
        let stateslantDirectionalCount = 0;
        let stateslantHorizontalCount = 0;
        let stateslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let stateUnknownCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;
        for (let stateQuery of statesToQuery) {
            for (let selectedState of isSelectedState) {
                if (selectedState.State == stateQuery) {
                    stateWellCount += selectedState.WellCount;
                    stateProductionCount += selectedState.WellProductionCount;
                    statewellActiveProducingCount += selectedState.WellActiveProducingCount;
                    stateslantSideTrackCount += selectedState.SlantSideTrackCount;
                    stateslantDirectionalCount += selectedState.SlantDirectionalCount;
                    stateslantVerticalCount += selectedState.SlantVerticalCount;
                    stateslantHorizontalCount += selectedState.SlantHorizontalCount;
                }
            }
        }
        for (let countyOpted of isSelectedCountyCount) {
            isselectedStaCountCount += countyOpted.Value;
        }
        if (paramstate.length === 0 && paramCounty.length > 0) {
            isSelectedCountyTotal = countiesToQUery.length;
        }
        else {
            isSelectedCountyTotal = isselectedStaCountCount + countiesToQUery.length;
            getWellCount = stateWellCount + prevCountRef.current;
            getProductionCount = stateProductionCount + prevCountProd.current;
            getwellActiveProducingCount = statewellActiveProducingCount + prevCountActiveProd.current;
            getslantSideTrackCount = stateslantSideTrackCount + prevCountSlantside.current;
            getslantDirectionalCount = stateslantDirectionalCount + prevCountSlantDirect.current;
            getslantHorizontalCount = stateslantHorizontalCount + prevCountSlantHorizontal.current;
            getslantVerticalCount = stateslantVerticalCount + prevCountSlantVertical.current;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;
        }
        selectedStateCount = statesToQuery.length + statesFromSelectedCounties.length;
        stateUnknownCount = stateWellCount - stateslantVerticalCount - stateslantSideTrackCount - stateslantDirectionalCount - stateslantHorizontalCount;
        setcount({ ...count, stateslantDirectionalCount, stateslantHorizontalCount, stateslantSideTrackCount, stateslantVerticalCount, selectedStateCount, getWellCount, statewellActiveProducingCount, getUnknownCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount, getslantVerticalCount, isSelectedCountyTotal, isselectedStaCountCount, stateWellCount, stateProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, stateUnknownCount })
    }
    async function isCountySelected(getCounty:CountyOption[], paramstate:AutoSelectOption[]) {
        let statesToQuery:string[] = []
        let countiesToQUery:string[] = [];
        let isCountylist = [];
        let statesFromSelectedCounties:string[] = [];
        for (let county of getCounty) {
            statesFromSelectedCounties.push(county.State);
            countiesToQUery.push(county.label.substring(5));
            isCountylist.push(county.label);
        }
        statesFromSelectedCounties = [...new Set(statesFromSelectedCounties)];
        countiesToQUery = [...new Set(countiesToQUery)];
        for (let state of paramstate) {
            statesToQuery.push(state.label);
        }
        statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));
        setLoaded(true);
        let body = {};
        if (getCounty.length >= 1 && paramstate.length === 0) {
            body = getBodyWithCounty(isCountylist);
        } else {
            body = getBodyWithStateAndCounty(countiesToQUery, statesFromSelectedCounties);
        }
        const responseData = await postAPI(body);
        const selectedCountyData = responseData?.data.Documents;
        setLoaded(false);
        let countyWellCount = 0;
        let countyProductionCount = 0;
        let countywellActiveProducingCount = 0;
        let countyslantDirectionalCount = 0;
        let countyslantHorizontalCount = 0;
        let countyslantSideTrackCount = 0;
        let countyslantVerticalCount = 0;
        let wellActiveProducingCount = 0;
        let slantSideTrackCount = 0;
        let slantDirectionalCount = 0;
        let slantVerticalCount = 0;
        let slantHorizontalCount = 0;
        let countyUnknownCount = 0;
        let isselectedStaCountCount = 0;
        let getWellCount = 0;
        let getProductionCount = 0;
        let getwellActiveProducingCount = 0;
        let getslantSideTrackCount = 0;
        let getslantDirectionalCount = 0;
        let getslantHorizontalCount = 0;
        let getslantVerticalCount = 0;
        let getUnknownCount = 0;        
        for (let countyList of isCountylist) {
            for (let selectedCounty of selectedCountyData) {
                if (countyList === selectedCounty.StateCounty) {
                    countyWellCount += selectedCounty.WellCount;
                    countyProductionCount += selectedCounty.WellProductionCount;
                    countywellActiveProducingCount += selectedCounty.WellActiveProducingCount;
                    countyslantSideTrackCount += selectedCounty.SlantSideTrackCount;
                    countyslantDirectionalCount += selectedCounty.SlantDirectionalCount;
                    countyslantVerticalCount += selectedCounty.SlantVerticalCount;
                    countyslantHorizontalCount += selectedCounty.SlantHorizontalCount;
                }
            }
            countyUnknownCount = countyWellCount - countyslantVerticalCount - countyslantSideTrackCount - countyslantDirectionalCount - countyslantHorizontalCount;
        }
        if (countiesToQUery.length > 0 && statesToQuery.length > 0) {
            isStateSelected(paramstate, getCounty);
        } else if (statesFromSelectedCounties.length > 0) {
            count.selectedStateCount = statesFromSelectedCounties.length;
            count.isSelectedCountyTotal = countiesToQUery.length;
            getWellCount = countyWellCount;
            getProductionCount = countyProductionCount;
            getwellActiveProducingCount = countywellActiveProducingCount;
            getslantDirectionalCount = countyslantDirectionalCount;
            getslantHorizontalCount = countyslantHorizontalCount;
            getslantVerticalCount = countyslantVerticalCount;
            getslantSideTrackCount = countyslantSideTrackCount;
            getUnknownCount = getWellCount - getslantVerticalCount - getslantSideTrackCount - getslantDirectionalCount - getslantHorizontalCount;
        }
        setcount({
            ...count, getUnknownCount, isselectedStaCountCount, getProductionCount, getwellActiveProducingCount, getslantSideTrackCount, getslantDirectionalCount, getslantHorizontalCount,
            getslantVerticalCount, countyWellCount, countywellActiveProducingCount, countyslantSideTrackCount, countyslantDirectionalCount, countyslantHorizontalCount, countyslantVerticalCount, getWellCount, countyProductionCount, wellActiveProducingCount, slantSideTrackCount, slantDirectionalCount, slantVerticalCount, slantHorizontalCount, countyUnknownCount
        })
        setValue({ ...state, removeDup: statesFromSelectedCounties });
    }

    function isClear() {
        let statesToQuery:string[] = [];
        let statesFromSelectedCounties:string[] = [];
        let countiesToQUery:string[] = [];
        setSelectedStateOption([]);
        setSelectedCountyOption([]);
        setCountyOptions(allCountyOptions);
        isListofStatesAndCounty();
        setValue({ ...state, statesToQuery: statesToQuery, statesFromSelectedCounties: statesFromSelectedCounties, countiesToQUery: countiesToQUery });
        setIsDis(false);
        setError("");
        setIsFileData("");
        setIsUwiSearchList([]);
        isLength.current = true;
        setCountWell(0)
    }
    const isCountyClear = (removedItem:CountyOption[]) => {
        setSelectedCountyOption(removedItem);
        if (removedItem.length === 0) {
            isStateSelected(selectedStateOption, removedItem);
        } else {
            isCountySelected(removedItem, selectedStateOption);
        }
        if (selectedStateOption.length === 0 && removedItem.length === 0) {
            isListofStatesAndCounty();
        }
    }

    const isStateClear = (removedItem:AutoSelectOption[]) => {
        const removeValue = selectedStateOption.filter(e => !removedItem.includes(e));
        const filteredSelCountiies11 = selectedCountyOption.filter(item => removedItem.some((ri:AutoSelectOption) => item.State === ri.key));
        if (removeValue.length > 0 && filteredSelCountiies11.length === 0 && selectedStateOption.length > 0) {
            isStateSelected(removedItem, selectedCountyOption);
        } else if (selectedCountyOption.length > 0 && selectedStateOption.length > 0) {
            isCountySelected(selectedCountyOption, removedItem)
        }
        if (removedItem.length === 0 && selectedStateOption.length <= 2) {
            const filteredSelCountiies1 = selectedCountyOption.filter(item => selectedStateOption.some((ri:AutoSelectOption) => item.State !== ri.key));
            setSelectedCountyOption(filteredSelCountiies1);
        } else if (removedItem.length > 0 && selectedStateOption.length > 2) {
            const diff = selectedStateOption.filter(e => !removedItem.includes(e));
            const filteredSelCountiies2 = selectedCountyOption.filter(item => diff.some((e:AutoSelectOption) => item.State !== e.key));
            setSelectedCountyOption(filteredSelCountiies2);
        } else {
            const remArr = selectedStateOption.filter(a => removedItem.some((r:AutoSelectOption) => a.label !== r.label));
            const filteredSelCountiies3 = selectedCountyOption.filter(item => remArr.some((e:AutoSelectOption) => item.State !== e.label));
            setSelectedCountyOption(filteredSelCountiies3);
        }
        if (removedItem.length === 0) {
            setCountyOptions(allCountyOptions);
        }
        if (removedItem.length === 0) {
            isListofStatesAndCounty();
        }
    }
    const onChangeFile = (e:ChangeEvent<HTMLInputElement>) => {
        let file = e.target .files?.[0];
        if(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            let targetValue=e.target?.result;
            if(targetValue){ 
            let lengtharr = targetValue.toString().split(/[^a-zA-Z0-9\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]+/g).filter((v: any) => v).join('\n');       
            let finalList = lengtharr.split('\n');
            if (!specialChar.test(targetValue.toString())) {
                isLength.current = false;
                setIsDis(false);
                setError("")
            } else {
                isLength.current = true;
                setIsDis(true);
                setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }

            finalList.forEach(valList => {
                if (valList.trim().length < 10 || valList.trim().length > 16 || /\s/.test(valList)) {
                    isLength.current = true;
                    setIsDis(true);
                    setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
                } else {
                    isLength.current = false;
                }
            })
            finalList = [...new Set(finalList)];
            finalList = finalList.filter((valListItem: string) => (valListItem.trim().length >= 10 && valListItem.trim().length <= 16 && !format.test(valListItem.trim())));
            setCountWell(finalList.length)
            setIsFileData(lengtharr);        
        };
    }
        reader.readAsText(file);
        }
    }
    const [error, setError] = useState("");

    const onChangeTextInFile = (e:ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value === '') {
            setCountWell(0);
            const file: HTMLInputElement | null = document.querySelector('#upload') as HTMLInputElement;
            console.log(file)
            file.value = '';
        }
        let old_val = isFileData;
        let new_str = e.target.value.split('').map((str, i) => {
            if (old_val[i] !== str) {
                return { val: str, position: i }
            }
        }).filter(val => val !== undefined)[0];
        let newArr = (new_str && ((new_str.position == e.target.value.length - 1) && new_str.val == ',')) ? true : false;
        if (newArr) {
            let newArr1 = e.target.value.split('');
            newArr1.splice(new_str!.position, 1, '\n');
            let newV = newArr1.join('');
            e.target.value = newV;
            setIsFileData(newV)
        }
        let str2 = e.target.value
        let str4 = str2.split('').map((row: string, i: number) => {
            if (str2[i - 1] === ' ' && row !== ' ') return '\n' + row.trim()
            return row;
        })
        if (!specialChar.test(e.target.value)) {
            isLength.current = false;
            setIsDis(false);
            setError("")
        } else {
            isLength.current = true;
            setIsDis(true);
            setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
        }
        let str3 = str4.join('').split('\n');
        str3 = str3.filter((entry: string) => entry.trim() != '');

        str3.map((string:string) => {
            if (string.trim().length < 10 || string.trim().length > 16) {
                isLength.current = true;
                setIsDis(true);
                setError("UWI’s must be between 10-16 characters and cannot include any of the following characters: $ % & # @ ! * ( )")
            }
        })
        str3 = [...new Set(str3)];
        let format = /[ `\/!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        str3 = str3.filter((valListItems: string) => (valListItems.trim().length >= 10 && valListItems.trim().length <= 16 && !format.test(valListItems.trim())));
        setCountWell(str3.length)
        setIsFileData(str4.join(''));
    }
    const [isUwiSearchList, setIsUwiSearchList] = useState<string[]>([]);
    function isUwiSearch() {
        const dataInFile= isFileData.split('\n');
        setIsUwiSearchList(dataInFile);
    }

    const [selectedTab, setSelectedTab] = useState('HeaderSearch');

    const handleSelect = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
        setIsDis(false);
        isLength.current = true;
    }
    const StyledTab = styled(Tab)({
        textTransform: 'none',
        minWidth:"50%",
        "&.Mui-selected": {
          background:'#e6f4fd',
          border: '1px solid gray',
          borderRadius: '7px',
          color:"#17abab",
        },
      });
    const highlightState=(valueChange:AutoSelectOption)=>{
        let result:AutoSelectOption[] = selectedStateOption.filter(o1 => !selectedCountyOption.some(o2 => o1.key === o2.State));
        let returnValue=''
        if(result.length > 0){
            result.forEach((value) => {   
                if(value.key === valueChange.key){
                            returnValue = 'tgs-saga-font-bold'
                        }
            })
             }
        return returnValue;
    }
    const highlightCounty=()=>{
         if(selectedCountyOption.length>0){
            return 'tgs-saga-font-bold'
         }
         return ''
    }
    const isExportBtnDisable = () => {
        if(selectedTab === 'HeaderSearch') {
            return selectedStateOption.length === 0 && selectedCountyOption.length === 0;
        } else {
            return isDis || isLength.current || isFileData.length == 0;
        }
    }
    const onClickExportBtn = () => {
        if(selectedTab === 'HeaderSearch') {
            setShowLogin(true);
        } else {
            setShowLogin(true);
            isUwiSearch();
        }
    }
    useEffect(() => {
        if (!authState?.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                if( info.name && info.email){
                    setUserInfo({ name: info.name, email: info.email});
                }                
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);
    useEffect(() => {
        isListofStatesAndCounty();
    }, []);
   
    return (
        <div className="page">
            <div className="content">
            <Header
                    logo={ <img className="max-"
                        src={`/assets/images/TGS_logo_white_XL.png`}
                        alt="logo" height="30" />}
                    project={<></> } menu={<></>} user={userInfo!}
                    logout={logout} UserButton={UserButton} SignInButton={Button}
                    />
                <div className="page d-flex">
                    <div className="col-md-1 searchCol" style={{ border: " 1px  solid #DCDCDC", height: '100vh' }}>
                        {loaded && <div id="cover-spin"></div>}
                        <div className="favIcons">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <button className="btn btn-info btnCls">
                                        <i className="fa fa-search" aria-hidden="true" style={{ color: 'white' }}></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="filterCol">
                        <div id="textbox">
                            <h5 className="alignleft" style={{ color: '#FFA500' }}>Production Data</h5>
                            <button className="btn btn-link alignright clrSearch cursorPointCls" onClick={isClear}>Clear Search</button>

                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Box sx={{ typography: 'body1'}}>
                        <TabContext value={selectedTab}>
                            <TabList onChange={handleSelect} aria-label="lab API tabs example">
                                <StyledTab label="Header Search" value="HeaderSearch" />
                                <StyledTab label="UWI/API List" value="uwlList" />
                            </TabList>
       
                            <TabPanel value="HeaderSearch">
                            <h6 style={{ display: 'flex' }}>Search by State and County</h6>
                            <AutocompleteSelect id="state-auto-complete-select" limitTags={10} isMultiple={true}
                                            options={stateOptions.map(e => ({ label: e.Key, key: e.Key }))}
                                            variant="outlined"
                                            value={selectedStateOption} placeHolder='Select State'
                                            onSelect={onSelectState} isOptionsLoading={false} 
                                            disableClearable={false} size="small"
                                            onInputBoxTagLabelDivClass={highlightState} />
                                <br />
                            <AutocompleteSelect id="county-auto-complete-select" limitTags={10} isMultiple={true}
                                            options={countyOptions}  filterOptionsLimit={-1}
                                            variant="outlined" value={selectedCountyOption} placeHolder='Select County'
                                            isOptionsLoading={false} onSelect={onSelectCounty} label='Select County' 
                                            disableClearable={false} size="small"
                                            onInputBoxTagLabelDivClass={highlightCounty} />
                            </TabPanel>
                            <TabPanel value="uwlList">
                            <div className="tablebox">
                                <p style={{ fontSize: '14px' }}>UWI/API List <span style={{ color: '#ff0000' }}>&nbsp;*</span></p>
                                <textarea cols={35} rows={5} name="descr" value={isFileData} onChange={onChangeTextInFile} ></textarea><br />
                                <p className='uwiErr mb-4'>{error}</p>
                                <label htmlFor="upload" className="uploadFile"><h6 style={{ marginTop: '-5px' }}>Or&nbsp;<i className="fa fa-upload" aria-hidden="true" style={{ marginTop: '4px' }}></i>&nbsp;Upload UWI/API List</h6></label>
                                <input type="file" name="photo" accept=".txt" id="upload" onChange={onChangeFile} />

                            </div>
                            </TabPanel>
                        </TabContext>
                        </Box>
                       

                    </div>
                    <div className="countCards">
                        <div className="card-sec">
                            <div className="card">
                                <div className="card-header">
                                    <b className="pull-left" style={{ color: '#FFA500' }}><span>Active Selection Counts</span></b>
                                    <button className="btn btn-primary font-size22 pull-right" type="button" disabled={isExportBtnDisable()} onClick={onClickExportBtn}>Export</button>
                                    <Export show={showLogin} close={() => setShowLogin(false)} dataSelectedOption={selectedStateOption} dataSelectedCounty={selectedCountyOption}
                                        dataUWiList={isUwiSearchList} dataSelectedTab={selectedTab} />
                                </div>
                                {selectedTab === 'HeaderSearch' ? (<div>
                                    <h6 className="m-3" style={{ textAlign: 'left', display: 'block' }}>Region</h6>
                                    <div className="container-fluid px-6">
                                        <div className="row gx-4">
                                            <div className="col">
                                                <div className="p-3 border bg-light shadow">
                                                    <div className="">
                                                        <div className="text-center">
                                                             <h5 className="card-title">
                                                                 {getWellCount(selectedStateOption, selectedCountyOption, count.selectedStateCount, selectedStateOption.length, state.removeDup.length, state.totalCountOfState)}
                                                             </h5>
                                                            <p>State Count </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="p-3 border bg-light shadow">
                                                    <div className="">
                                                        <div className="text-center">                                                            
                                                                 <h5 className="card-title">
                                                                   {getWellCount(selectedStateOption, selectedCountyOption, count.isSelectedCountyTotal, count.isselectedStaCountCount, selectedCountyOption.length, state.totalCountOfCounty)}
                                                                 </h5>
                                                        </div>
                                                        <p>County Count</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <Count data={count} data2={selectedStateOption} data3={selectedCountyOption} />
                                    </div>
                                </div>) : (
                                    <div className="container-fluid px-6">
                                        <div className='row gx-4'>
                                            <div className="">
                                                <div className="p-3 border bg-light shadow uwicardInside ">
                                                    <div className="text-center"><h5 className="card-title">{countWell.toLocaleString()}</h5><p>Well Count </p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div >
            </div>
            {isDialogOpen && <MessageDialog title={""}
                                         messageIcon={<InfoOutlinedIcon className="info-icon" sx={{ fontSize: 60 }} />}
                                         messageHeadText={'Your session has expired.'}
                                         messageText={'Click OK to return to the Login page.'} 
                                         openDialog={isDialogOpen} yesButtonText={"OK"} handleYesChange={() => { setIsDialogOpen(false); oktaAuth.signOut(); }} />}
        </div >
    );
}

export default Home;
