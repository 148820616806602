import { AutoSelectOption } from "tgsccl-core-mui/components/auto-complete-select/auto-complete-select";
import { CountyOption } from "../IselectInput-interface";

export const onLoadStateInputBody = () => {
    return {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
            {
                "Field": "WellActiveProducingCount",
                "NotEqualOperator": false,
                "Value": 0
            },
            {
                "Field": "Country",
                "EqualOperator": false,
                "Value": "UNITED STATES OF AMERICA"
            }
        ],
        "Aggregations": [
            {
                "AggregateBy": "State"
            }
        ],
        "From": 0,
        "Size": 0
    }
}
export const onLoadCountyInputBody = () => {
    return {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
            {
                "Query": "WellActiveProducingCount: >0",
                "NotEqualOperator": false,
                "Value": 0
            },
            {
                "Field": "Country",
                "EqualOperator": true,
                "Value": "UNITED STATES OF AMERICA"
            }
        ],
        "IncludeFields": ["Country", "State", "StateCode", "StateCounty"],
        "From": 0,
        "Size": 5000
    }
}
export const onLoadStateCount = (states:string[]) => {
    return {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
            {
                "Field": "State",
                "Values": states,
                "NotEqualOperator": false
            }
        ],
        "Aggregations": [
            {
                "AggregateBy": "State",
                "PropertyName": "WellCount",
                "AggregationTypes": [
                    "Sum"
                ]
            }
        ],
        "From": 0,
        "Size": 10000
    }
}
export const selectedStateInputBody = (statesToQuery:string[]) => {
    return {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
            {
                "Field": "State",
                "Values": statesToQuery,
                "NotEqualOperator": false
            }
        ],
        "Aggregations": [
            {
                "AggregateBy": "State",
                "PropertyName": "WellCount",
                "AggregationTypes": ["Sum"]
            }
        ],
        "From": 0,
        "Size": 10000
    }
}
export const getBodyWithCounty = (isCountylist: string[]) => {
    return {
        "SearchType": "WpdProductCounts",
        "SearchFieldValues": [
            {
                "Field": "StateCounty",
                "NotEqualOperator": false,
                "Values": isCountylist
            }
        ],
        "Aggregations": [
            {
                "AggregateBy": "Country",
                "OrderBy": "Value",
                "Order": "asc",
                "PropertyName": "WellCount",
                "AggregationTypes": [
                    "Sum"
                ]
            },
            {
                "AggregateBy": "Country",
                "OrderBy": "Value",
                "Order": "asc",
                "PropertyName": "WellActiveProducingCount",
                "AggregationTypes": ["Sum"]
            }
        ],
        "From": 0,
        "Size": 5000
    }
}
export const getBodyWithStateAndCounty = (countiesToQUery: string[], statesFromSelectedCounties: string[]) => {
return {
    "SearchType": "WpdProductCounts",
    "SearchFieldValues": [
        {
            "Field": "County",
            "NotEqualOperator": false,
            "Value": null,
            "Values": countiesToQUery
        },
        {
            "Field": "State",
            "Values": statesFromSelectedCounties,
            "NotEqualOperator": false
        }
    ],
    "Aggregations": [
        {
            "AggregateBy": "State",
            "PropertyName": "WellCount",
            "AggregationTypes": ["Sum"]
        }
    ],
    "From": 0,
    "Size": 10000
}
}
export const getWellCount = (selectedStateOption: AutoSelectOption[], selectedCountyOption: CountyOption[], getWellCount: number, stateWellCount: number, countyWellCount:number, wellcount: number) => {
    if (selectedStateOption.length > 0 && selectedCountyOption.length > 0) {
        return Number(getWellCount).toLocaleString('en-GB');
      } else if (selectedCountyOption.length > 0) {
        return Number(countyWellCount).toLocaleString('en-GB');
      } else if(selectedStateOption.length > 0) {
        return Number(stateWellCount).toLocaleString('en-GB');
      } else {
        return Number(wellcount).toLocaleString('en-GB');
      }
}
export const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const specialChar = /[\!\@\#\$\%\^\&\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\/\\\\]/g;